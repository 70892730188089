.home {
    background-image: url('https://media.giphy.com/media/cx4tSBNyliubHpUY3Q/giphy.gif'); 
    background-size: contain;
    height: 1200px;
}

.home h1 {
    font-size: 50px;
    margin: 50px;
}

.home h1 a {
    text-decoration: none;
    color: white;
}

.home h1 a:hover {
    text-decoration: underline;
}

@media all and (max-width: 400px) {
    .home h1 {
        font-size: 30px;
        margin: 50px;
    }
}