@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  /* background-image: url('https://media.giphy.com/media/3o6fIYWU6HVdxrqmBO/giphy.gif'); */
  /* background-image: url('https://media.giphy.com/media/Z69UDgjfRMjsY/giphy.gif'); */
  /* background-image: url('https://media.giphy.com/media/ASNZFgrWMQGdgbFmxz/giphy.gif');
  background-size: cover;
  background-size: contain;
  height: 1200px; */
}

@media all and (max-width: 400px) {
  .App {
    height: 800px;
  }
}

.home {
    background-image: url('https://media.giphy.com/media/cx4tSBNyliubHpUY3Q/giphy.gif'); 
    background-size: contain;
    height: 1200px;
}

.home h1 {
    font-size: 50px;
    margin: 50px;
}

.home h1 a {
    text-decoration: none;
    color: white;
}

.home h1 a:hover {
    text-decoration: underline;
}

@media all and (max-width: 400px) {
    .home h1 {
        font-size: 30px;
        margin: 50px;
    }
}
header img {
    height: 120px;
    padding: 10px 50px 10px 50px;
    margin-top: 30px;
}

/* media query for phone */

@media all and (max-width: 400px) {
    header img {
        height: 70px;
        margin: 0 auto;
        margin-top: 20px;
    }
}
.watch {
    background-image: url('https://media.giphy.com/media/wF1LLhfCezNqAcBPNZ/giphy.gif');
    /* background-image: url('https://media.giphy.com/media/cx4tSBNyliubHpUY3Q/giphy.gif');  */
    background-size: contain;
    height: 1200px;
}

.watch iframe {
    display: block;
    margin: 0 auto;
    margin-top: 70px;
    width: 660px; 
    height: 415px; 
}

@media all and (max-width: 400px) {
    .watch iframe {
        width: 360px; 
        height: 215px; 
    }
}
.listen {
    background-image: url('https://media.giphy.com/media/o9ZleMLL9a78eE1l4h/giphy.gif');
    background-size: contain;
    height: 1200px;
}

.listen .spotify {
    margin: 0 auto;
    margin-top: 70px;
    width: 800px; 
    height: 400px;
}

.listen .soundcloud {
    width: 800px;
    height: 100px;
}

/* media query for phone */

@media all and (max-width: 400px) {
    .listen .spotify, .listen .soundcloud {
        margin-bottom: 15px;
    }

    .listen .spotify {
        width: 350px;
        margin-top: 30px;
        height: 300px;
    }

    .listen .soundcloud {
        width: 350px;
        height: 80px;
    }
}

/* media query for tablet */

@media all and (min-width: 450px) and (max-width: 1000px) {
    .listen .spotify, .listen .soundcloud {
        width: 650px;
    }
}
.connect {
    background-image: url('https://media.giphy.com/media/ASNZFgrWMQGdgbFmxz/giphy.gif');
    background-size: contain;
    height: 1200px;
}

.connect div iframe {
    width: 20%;
}

.connect div svg, .connect div iframe {
    padding: 40px;
    margin: 0 auto;
}

.connect div a:link {
    color: white;
}

.connect div a:visited {
    color: white;
}

/* media query for phone */

@media all and (max-width: 400px) {
    .connect div svg, .connect div iframe {
        margin: 30px;
    }

    .connect div iframe {
        padding: 0;
        width: 60%;
    }

    .connect div svg {
        padding: 15px;
    }
}

/* media query for tablet */

@media all and (min-width: 450px) and (max-width: 1000px) {
    .connect div svg, .connect div iframe {
        padding: 30px;
    }

    .connect div iframe {
        margin: 0 auto;
        width: 30%;
    }
}
.contact {
    background-image: url('https://media.giphy.com/media/qudsVIyyfwXegaioWB/giphy.gif');
    background-size: contain;
    height: 1200px;
}

h1 {
    color: white;
}

/* media query for phone */

@media all and (max-width: 400px) {
    h1 {
        width: 70%;
        margin: 0 auto;
    }
}
