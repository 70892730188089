header img {
    height: 120px;
    padding: 10px 50px 10px 50px;
    margin-top: 30px;
}

/* media query for phone */

@media all and (max-width: 400px) {
    header img {
        height: 70px;
        margin: 0 auto;
        margin-top: 20px;
    }
}