.watch {
    background-image: url('https://media.giphy.com/media/wF1LLhfCezNqAcBPNZ/giphy.gif');
    /* background-image: url('https://media.giphy.com/media/cx4tSBNyliubHpUY3Q/giphy.gif');  */
    background-size: contain;
    height: 1200px;
}

.watch iframe {
    display: block;
    margin: 0 auto;
    margin-top: 70px;
    width: 660px; 
    height: 415px; 
}

@media all and (max-width: 400px) {
    .watch iframe {
        width: 360px; 
        height: 215px; 
    }
}