@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  /* background-image: url('https://media.giphy.com/media/3o6fIYWU6HVdxrqmBO/giphy.gif'); */
  /* background-image: url('https://media.giphy.com/media/Z69UDgjfRMjsY/giphy.gif'); */
  /* background-image: url('https://media.giphy.com/media/ASNZFgrWMQGdgbFmxz/giphy.gif');
  background-size: cover;
  background-size: contain;
  height: 1200px; */
}

@media all and (max-width: 400px) {
  .App {
    height: 800px;
  }
}
