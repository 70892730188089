.listen {
    background-image: url('https://media.giphy.com/media/o9ZleMLL9a78eE1l4h/giphy.gif');
    background-size: contain;
    height: 1200px;
}

.listen .spotify {
    margin: 0 auto;
    margin-top: 70px;
    width: 800px; 
    height: 400px;
}

.listen .soundcloud {
    width: 800px;
    height: 100px;
}

/* media query for phone */

@media all and (max-width: 400px) {
    .listen .spotify, .listen .soundcloud {
        margin-bottom: 15px;
    }

    .listen .spotify {
        width: 350px;
        margin-top: 30px;
        height: 300px;
    }

    .listen .soundcloud {
        width: 350px;
        height: 80px;
    }
}

/* media query for tablet */

@media all and (min-width: 450px) and (max-width: 1000px) {
    .listen .spotify, .listen .soundcloud {
        width: 650px;
    }
}