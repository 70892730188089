.connect {
    background-image: url('https://media.giphy.com/media/ASNZFgrWMQGdgbFmxz/giphy.gif');
    background-size: contain;
    height: 1200px;
}

.connect div iframe {
    width: 20%;
}

.connect div svg, .connect div iframe {
    padding: 40px;
    margin: 0 auto;
}

.connect div a:link {
    color: white;
}

.connect div a:visited {
    color: white;
}

/* media query for phone */

@media all and (max-width: 400px) {
    .connect div svg, .connect div iframe {
        margin: 30px;
    }

    .connect div iframe {
        padding: 0;
        width: 60%;
    }

    .connect div svg {
        padding: 15px;
    }
}

/* media query for tablet */

@media all and (min-width: 450px) and (max-width: 1000px) {
    .connect div svg, .connect div iframe {
        padding: 30px;
    }

    .connect div iframe {
        margin: 0 auto;
        width: 30%;
    }
}