.contact {
    background-image: url('https://media.giphy.com/media/qudsVIyyfwXegaioWB/giphy.gif');
    background-size: contain;
    height: 1200px;
}

h1 {
    color: white;
}

/* media query for phone */

@media all and (max-width: 400px) {
    h1 {
        width: 70%;
        margin: 0 auto;
    }
}